<template>
  <div class="v_promote_tips">
    <p class="v_promote_tips_item">一:  受邀人安装注册并成功完成首单任务后邀请人得<span>{{$global.config.SPREAD_BUY.spread_first}}</span>元奖励</p>
    <p class="v_promote_tips_item">二:  受邀人每次接收任务赚钱佣金，你可以最高得到佣金的<span>{{$global.config.SPREAD_BUY.spread_1}}%</span>作为额外奖励，受邀人再邀请会员也可以得额外奖励。邀请人数越多，赚钱的佣金奖励也就越多！奖励如下：</p>
    <p class="v_promote_tips_item"> <i></i> 邀请人数<span>1-20</span>人可获得一级代理佣金的<span>{{$global.config.SPREAD_BUY.spread_20_1}}%</span>，二级代理佣金的<span>{{$global.config.SPREAD_BUY.spread_20_2}}%</span>;</p>
    <p class="v_promote_tips_item"> <i></i> 邀请人数<span>20-50</span>人可获得一级代理佣金的<span>{{$global.config.SPREAD_BUY.spread_50_1}}%</span>，二级代理佣金的<span>{{$global.config.SPREAD_BUY.spread_50_2}}%</span>;</p>
    <p class="v_promote_tips_item"> <i></i> 邀请人数<span>50-150</span>人可获得一级代理佣金的<span>{{$global.config.SPREAD_BUY.spread_150_1}}%</span>，二级代理佣金的<span>{{$global.config.SPREAD_BUY.spread_150_2}}%</span>;</p>
    <p class="v_promote_tips_item"> <i></i> 邀请人数<span>150</span>人可获得一级代理佣金的<span>{{$global.config.SPREAD_BUY.spread_1}}%</span>，二级代理佣金的<span>{{$global.config.SPREAD_BUY.spread_2}}%</span>;</p>
    <p class="v_promote_tips_item"> <i></i> 所有人数以审核通过人数为准。</p>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss">
.v_promote_tips {
  padding: 18px 16px;
  background: #F1F1F1;
  .v_promote_tips_item {
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 24px;
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: $main_color;
      border-radius: 50px;
    }
    span {
      color: $main_color;
    }
  }
}
</style>