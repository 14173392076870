<template>
  <div class="v_promote g-flex-column">
    <head-back>
      <template v-slot:returnText>
        <span>返回</span>
      </template>
    </head-back>

    <div class="v-promote-container">
      <div class="v-promote-head g-flex-column g-flex-align-center">
        <p class="v-promote-head-title">总收益</p>
        <div class="v-promote-head-line"></div>
        <span class="v-promote-head-money">{{form.money}}</span>
      </div>

      <div class="v-promote-card">
        <ul class="v-promote-card-list">
          <li class="v-promote-card-item g-flex-align-center">
            <div class="v-promote-card-item-left g-flex-align-center">
              <i class="iconfont icon-renqun"></i>
              <span class="v-promote-card-item-left-title">徒弟</span>
            </div>
            <div class="v-promote-card-item-right g-flex-align-center">
              <p>已邀请<span>{{form.tBuyNmus}}</span>人，已审核通过<span>{{form.tRealBuyNmus}}</span>人</p>
            </div>
          </li>
          <li class="v-promote-card-item g-flex-align-center">
            <div class="v-promote-card-item-left g-flex-align-center">
              <i class="iconfont icon-Group"></i>
              <span class="v-promote-card-item-left-title">商家</span>
            </div>
            <div class="v-promote-card-item-right g-flex-align-center">
              <p>已邀请<span>{{form.tShopNmus}}</span>人</p>
            </div>
          </li>
        </ul>
      </div>

      <div class="v-promote-btn" v-if="userInfo.spread_status == 0">
        <operation-button @clickItem="emitClickItem" :buttonObj="buttonObj"/>
      </div>

      <div class="v-promote-btn" v-if="userInfo.spread_status == 1">
         <operation-button @clickItem="emitClickItem" :buttonObj="buttonObjOne"/>
      </div>

      <div class="v-promote-btn" v-if="userInfo.spread_status == 1">
        <operation-button @clickItem="emitClickItem" :buttonObj="buttonObjTwo"/>
      </div>

      <!-- <ul class="v-promote-bottom-list">
        <li class="v-promote-bottom-list-item g-flex-align-center">
          <span class="v-promote-bottom-list-item-title">推广二维码</span>
          <i class="iconfont icon-you"></i>
        </li>
      </ul>  -->

      <ul class="v-promote-bottom-list">
        <!-- <li class="v-promote-bottom-list-item g-flex-align-center" @click="$router.push({ name: 'mypromoteorder' })">
          <span class="v-promote-bottom-list-item-title">推广订单</span>
          <i class="iconfont icon-you"></i>
        </li> -->
        <li class="v-promote-bottom-list-item g-flex-align-center" @click="$router.push({ name: 'myteam' })">
          <span class="v-promote-bottom-list-item-title">我的团队</span>
          <i class="iconfont icon-you"></i>
        </li>
        <li class="v-promote-bottom-list-item g-flex-align-center">
          <span class="v-promote-bottom-list-item-title" @click="$router.push({ name: 'mypromotestore' })">推广商家</span>
          <i class="iconfont icon-you"></i>
        </li>
      </ul> 
      
      <my-promote-tips/>
    </div>
  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  import OperationButton from '../../components/OperationButton.vue'
  import MyPromoteTips from './MyPromoteTips.vue'
  import { apiGetSpreadTotal } from '@/utils/api.js'
  export default {
    components: { HeadBack, OperationButton, MyPromoteTips },
    data() {
      return {
        userInfo: this.$global.userInfo,
        buttonObj: {
          claSS: 'c-btn-default c-btn-border-radius',
          title: '请联系客服开通推广权限',
          event: 'promoteClick'
        },
        buttonObjOne : {
          claSS: 'c-btn-default c-btn-border-radius',
          title: '复制邀请用户链接',
          event: 'copyInviteUser'
        },
        buttonObjTwo : {
          claSS: 'c-btn-default c-btn-border-radius',
          title: '复制邀请商家链接',
          event: 'copyInviteShop'
        },
        form: {
          money: 0,
          tBuyNmus: 0,
          tRealBuyNmus: 0,
          tShopNmus: 0,
        }
      }
    },
    created() {
      this.apiGetSpreadTotalHandel()
    },
    methods: {
      // 获取推广数据
      async apiGetSpreadTotalHandel() {
        this.$global.loadingShow = true
        const { success, data } = await apiGetSpreadTotal()
        if(!success) return
        this.form = data.data
        console.log(data)
      },
      emitClickItem(event) {
        if(!event) return
        this[event]()
      },
      promoteClick() {
        this.$dialog.alert({
          message: '请联系客服开通推广权限',
          confirmButtonColor: '#F9436B'
        }).then(() => {})
      },

      copyInviteUser() {
        this.copyClick(this.userInfo.buy_spread)
      },

      copyInviteShop() {
        this.copyClick(this.userInfo.shop_spread)
      },
      copyClick(message) {
        this.$copyText(message).then(() => {
          this.$toast({
            type: 'success',
            message: '复制成功'
          });
        },  () => {
          this.$toast('复制失败,请重试');
        })
      },
    }
  }
</script>

<style lang="scss">
.v_promote {
  height: 100%;
  background: #F1F1F1;
  .v-promote-container {
    flex: 1;
    overflow: auto;
    .v-promote-head {
      background: $main_color;
      padding: 18px 0;
      min-height: 150px;
      color: $white;
      .v-promote-head-title {
        font-size: 18px;
        padding-bottom: 8px;
      }
      .v-promote-head-line {
        width: 16px;
        height: 2px;
        background: $white;
        border-radius: 2px;
      }

      .v-promote-head-money {
        font-size: 26px;
        padding-top: 14px;
      }
    }
    .v-promote-card {
      min-height: 100px;
      position: relative;
      .v-promote-card-list {
        width: 94%;
        position: absolute;
        top: 0;
        transform: translateX(-50%) translateY(-35%);
        left: 50%;
        border-radius: 8px;
        background: $white;
        .v-promote-card-item {
          padding: 20px 18px;
          border-bottom: 1px solid #DCDCDC;
          .v-promote-card-item-left {
            .iconfont {
              font-size: 22px;
              color: $main_color;
            }
            .v-promote-card-item-left-title {
              font-size: 14px;
              padding-left: 6px;
              color: #000000;
              line-height: 25px;
            }
          }
          .v-promote-card-item-right {
            flex: 1;
            p {
              width: 100%;
              text-align: right;
              font-size: 12px;
              color: #000000;
              span {
                color: $main_color;
              }
            }
          }
        }
      }
    }

    .v-promote-btn {
      margin-top: 10px;
    }

    .v-promote-bottom-list {
      margin-top: 15px;
      background: $white;
      .v-promote-bottom-list-item {
        padding: 18px;
        border-bottom: 1px solid #EEEEEE;
        &:nth-last-of-type(1) {
          border-bottom: none;
        }
        .v-promote-bottom-list-item-title {
          flex: 1;
          font-size: 16px;
          color: #000000;
        }
        .iconfont {
          font-size: 18px;
          color: #7D929C;
        }
      }
    }
  }
}
</style>